<template>
  <div class="refund-view" v-loading="loading">
    <div class="title"><i class="el-icon-question"></i> 是否确认强制完结服务</div>
    <div class="info">(完结后无法恢复，立即生效)</div>
    <div class="admin-dialog-btns">
      <el-button type="primary" @click="submitHandler">确认</el-button>
      <el-button
        @click="
          () => {
            this.$emit('end');
          }
        "
        >取消
      </el-button>
    </div>
  </div>
</template>

<script>
import {drvStudentForcedOver} from '../../api/index';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {},
  methods: {
    submitHandler() {
      this.loading = true;
      drvStudentForcedOver({businessSn: this.$props.row.businessSn}).then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          this.$emit('success');
          this.$emit('end');
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.refund-view {
  text-align: center;
  .title {
    font-size: 20px;
    color: #333;
    height: 30px;
    line-height: 30px;
    i {
      font-size: 35px;
      color: blue;
      vertical-align: middle;
    }
  }
  .info {
    font-size: 14px;
    color: #666;
    margin-top: 15px;
  }
  .admin-dialog-btns {
    margin-top: 25px;
  }
}
</style>
